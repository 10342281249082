import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CustomCheckBox = ({ isDisable = true, label = '', control, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} disabled={isDisable} />}
          label={label}
        />
      )}
    />
  );
};

export default CustomCheckBox;

import useMap from './Map';
import useRoleSelect from './RoleSelect';
import useUserSelect from './UserSelect';
import useCalendar from './Calendar';

const useComponentsControllers = () => {
  return {
    useMap,
    useRoleSelect,
    useUserSelect,
    useCalendar,
  }
}
export default useComponentsControllers;
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box, useTheme } from '@mui/material';


const useCalendar = ({ setSelectedDate }) => {
  const [innerSelectedDate, setInnerSelectedDate] = useState(dayjs(Date.now()));
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const { palette } = theme;


  const data = [
    {
      date: '2025-02-03',
      state: 'closed',
      value: 1,
    },
    {
      date: '2025-02-04',
      state: 'pending',
      value: 2,
    },
    {
      date: '2025-02-05',
      state: 'pending',
      value: 3,
    },
    {
      date: '2025-02-07',
      state: 'pending',
    },
  ];

  const getHighlightedDays = () => {
    const highlightedDays = [3, 4, 5, 6].map(day => dayjs(`2025-02-${day}`));
    return highlightedDays;
  };


  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;

    const currentDay = dayjs(props.day).format('YYYY-MM-DD');
    const isClosed = data.some((x) => x.date === currentDay && x.state === 'closed');
    const isPending = data.some((x) => x.date === currentDay && x.state === 'pending');
    const isSelected = innerSelectedDate.format('YYYY-MM-DD') === currentDay;

    return (

      <Box position={'relative'}>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          sx={{
            ...(isClosed && { border: '1px solid lightGreen', color: 'white' }),
            ...(isPending && { border: '1px solid orange', color: 'white' }),
            ...(isSelected && { backgroundColor: palette.primary.main, color: 'white' }),
          }}
          onDaySelect={() => {
            setSelectedDate(dayjs(day).format('YYYY-MM-DD'));
            setInnerSelectedDate(dayjs(day));
          }}
        />
      </Box>
    );
  }

  useEffect(() => {
    console.log(innerSelectedDate);
  }, [innerSelectedDate]);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return {
    isLoading,
    innerSelectedDate,
    getHighlightedDays,
    ServerDay
  }
}

export default useCalendar;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useRoleService from '../../../services/role';

const useRoleSelect = ({ selectedValue = null, onChange }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const { getRoles } = useRoleService();

  const handleSelectedRole = (event, value) => {
    setSelectedRole(value);
    onChange?.(value);
  };

  useEffect(() => {
    const handleGetRoles = async () => {
      const { roles: rolesAux } = await getRoles({ page: -1 });
      setRoles(rolesAux);
      const role = rolesAux.find((role) => role.id === selectedValue);

      if (role) {
        setSelectedRole(role);
      } else {
        setSelectedRole(null);
      }
    };

    handleGetRoles();
  }, []);

  return {
    roles,
    selectedRole,
    handleSelectedRole
  };
};

export default useRoleSelect;

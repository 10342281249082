
import {
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
  IconButton,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomButton } from '../components/CustomButton';
import useComponent from '../components';
import usePublicLighting from '../controllers/viewsControllers/PublicLighting';

const PublicLighting = () => {
  const { CustomInput } = useComponent();
  const {
    handleFieldChange,
    handleSelectedUsers,
    handleSelectedVehicles,
    handleAddActivity,
    handleRemovePair,
    handleGetActivityName,
    handleGetVehicleName,
    handleGetUserName,
    handleRemoveUser,
    handleRemoveVehicle,
    handleRemoveOtherVehicle,
    handleSelectedActivity,
    handleSave,
    handleFinishDisabled,
    setSelectedCrew,
    setSelectedOT,
    setSelectedActivity,
    setSelectedActivityQuantity,
    setSelectedVehicle,
    setSelectedOtherVehicle,
    setSelectedUsers,
    setSelectedCity,
    selectedCity,
    selectedOtherVehicle,
    selectedUsers,
    selectedActivity,
    selectedActivityQuantity,
    selectedCrew,
    selectedOT,
    selectedVehicle,
    activities,
    users,
    activityPairs,
    actualUserId,
    vehicles
  } = usePublicLighting();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={2} color="primary">
          Alumbrado Público
        </Typography>
        <Box>

          {(selectedUsers.length > 0 || activityPairs.length > 0) && (
            <Box sx={{ mt: 4, p: 2 }}>
              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" color="primary">
                  Ciudad
                </Typography>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="crew-dropdown">Seleccionar ciudad</InputLabel>
                  <Select
                    label="Seleccionar ciudad"
                    labelId="crew-dropdown"
                    value={selectedCity}
                    onChange={(e) => handleFieldChange({ value: e.target.value, setValue: setSelectedCity, localStorageKey: 'cityPL' })}
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value={'Medellín'} >Medellín</MenuItem>
                    <MenuItem value={'Cartagena'}>Cartagena</MenuItem>
                  </Select>
                </FormControl>
              </Paper>

              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>


                <Typography variant="h6" color="primary">
                  Cuadrilla
                </Typography>


                <FormControl fullWidth margin="normal">
                  <CustomInput
                    label="OT"
                    type="text"
                    value={selectedOT}
                    onChange={(value) => handleFieldChange({ value: value, setValue: setSelectedOT, localStorageKey: 'otPL' })}
                    fullWidth
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="crew-dropdown">Seleccionar tipo cuadrilla</InputLabel>
                  <Select
                    label="Seleccionar tipo cuadrilla"
                    labelId="crew-dropdown"
                    value={selectedCrew}
                    onChange={(e) => handleFieldChange({ value: e.target.value, setValue: setSelectedCrew, localStorageKey: 'crewPL' })}
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value={'EXPANSION'}>Expansión</MenuItem>
                    <MenuItem value={'MAINTENANCE'}>Mantenimiento</MenuItem>
                  </Select>
                </FormControl>
              </Paper>

              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" color="primary">
                  Usuarios
                </Typography>
                <Box>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={users}
                    getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
                    value={handleSelectedUsers(selectedUsers)}
                    defaultValue={[]}
                    filterSelectedOptions
                    onChange={(e, value) => handleFieldChange({
                      value,
                      arrayValue: selectedUsers,
                      setValue: setSelectedUsers,
                      localStorageKey: 'selectedUsersPL'
                    })}
                    sx={{ width: '100%', mb: 4, mt: 3 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seleccionar usuarios"
                      />
                    )}
                  />
                </Box>
              </Paper>

              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" color="primary">
                  Vehículos
                </Typography>
                <Box>
                  <Autocomplete
                    multiple
                    id="vehicles-select"
                    options={vehicles}
                    getOptionLabel={(option) => {
                      return `${option?.license_plate} - ${option?.vehicle_type.name}`
                    }}
                    value={handleSelectedVehicles(`${selectedVehicle} `)}
                    defaultValue={[]}
                    filterSelectedOptions
                    onChange={(e, value) => handleFieldChange({
                      value,
                      arrayValue: selectedVehicle,
                      setValue: setSelectedVehicle,
                      localStorageKey: 'vehiclesPL'
                    })}
                    sx={{ width: '100%', mt: 3 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seleccionar vehículos"
                      />
                    )}
                  />
                  <FormControl fullWidth margin="normal">
                    <CustomInput
                      label="Otros vehículos"
                      placeholder="Ingrese la placa del vehículo"
                      type="text"
                      value={selectedOtherVehicle}
                      onChange={(value) =>
                        handleFieldChange({
                          value: value.toUpperCase(),
                          setValue: setSelectedOtherVehicle,
                          localStorageKey: 'otherVehiclePL'
                        })
                      }
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Paper>

              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" marginBottom={0} color="primary">
                  Actividad
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <FormControl fullWidth margin="normal">

                    <Autocomplete
                      id="activities-select"
                      options={activities}
                      getOptionLabel={(option) => { return option.name }}
                      value={handleSelectedActivity(selectedActivity)}
                      defaultValue={[]}
                      filterSelectedOptions
                      onChange={(e, value) => handleFieldChange({
                        value: value ? value.id : '',
                        arrayValue: selectedActivity,
                        setValue: setSelectedActivity,
                        localStorageKey: 'activityPL'
                      })}
                      sx={{ width: '100%', mt: 3 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seleccionar actividad"
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth margin="normal" disabled={!selectedActivity}>
                    <CustomInput
                      label="Cantidad de actividad"
                      type="number"
                      disabled={!selectedActivity}
                      value={selectedActivityQuantity}
                      onChange={(value) =>
                        handleFieldChange({
                          value: value,
                          setValue: setSelectedActivityQuantity,
                          localStorageKey: 'activityQuantityPL'
                        })
                      }
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                    />
                  </FormControl>

                  <FormControl fullWidth margin="normal" disabled={!selectedActivity}>
                    <CustomButton
                      type="button"
                      isLoading={false}
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleAddActivity()}
                      text='Agregar'
                      disabled={!selectedActivity || !selectedActivityQuantity || !selectedVehicle}
                    />
                  </FormControl>
                </Box>
              </Paper>

              {
                <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                  {selectedUsers.length > 0 &&
                    <>
                      <Typography variant="h6" marginBottom={0} color="primary">
                        Usuarios Seleccionados
                      </Typography>
                      <List>
                        {selectedUsers?.map((userId, index) => (
                          <ListItem key={userId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                              primary={handleGetUserName(userId)}
                            />
                            {
                              actualUserId !== userId &&
                              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(index)}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          </ListItem>
                        ))}
                      </List>
                    </>
                  }

                  {selectedVehicle.length > 0 &&
                    <>
                      <Typography variant="h6" marginBottom={0} color="primary">
                        Vehículos propios seleccionados
                      </Typography>

                      <List>
                        {selectedVehicle?.map((vehicleId, index) => (
                          <ListItem key={vehicleId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ListItemText
                              primary={handleGetVehicleName(vehicleId)}
                            />
                            {
                              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveVehicle(vehicleId)}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          </ListItem>
                        ))}
                      </List>
                    </>
                  }

                  {
                    (selectedOtherVehicle !== '') &&
                    <>
                      <Typography variant="h6" marginBottom={0} color="primary">
                        Vehículos externos
                      </Typography>
                      <ListItem key={selectedOtherVehicle} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ListItemText primary={selectedOtherVehicle} />
                        {
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveOtherVehicle()}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </ListItem>
                    </>
                  }

                  {activityPairs.length > 0 &&
                    <>
                      <Typography variant="h6" marginBottom={0} color="primary">
                        Actividades realizadas
                      </Typography>
                      <List>
                        {activityPairs.map((pair, index) => (
                          <ListItem key={`${pair}-${index}`}
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #e0e0e0' }}
                          >
                            <Box>
                              <ListItemText
                                primary={`Actividad: ${handleGetActivityName(pair.selectedActivity)}`}
                              />
                              <ListItemText primary={`Cantidad: ${pair.selectedActivityQuantity}`} />
                            </Box>
                            <Box>
                              <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePair(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  }
                </Paper>
              }

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: '2rem' }}>
                <CustomButton
                  type="button"
                  isLoading={false}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={handleFinishDisabled()}
                  onClick={() => handleSave()}
                  text='Terminar'
                />
              </Box>
            </Box>
          )
          }
        </Box >
      </Container >

    </Box >
  );
};

export default PublicLighting;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/material';
import { CustomButton } from '../components/CustomButton';
import { CustomLink } from '../components/CustomLink';
import { useSnackbar } from '../components/CustomSnackBar';
import axios from 'axios';
import useComponent from '../components';

const ResetPassword = ({ isEmailChange }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { CustomInput } = useComponent();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (!token) {
      setError('Token de restablecimiento no válido.');
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    setIsLoading(true);
    try {
      const body = isEmailChange ? { password, email } : { password };
      const response = await axios.post(`${apiUrl}/password/reset-password/${token}`, JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (response.status === 200) {
        setPassword('');
        setConfirmPassword('');
        setEmail('');
        setConfirmEmail('');
        showSnackbar('Su contraseña ha sido restablecida exitosamente.', 5000, 'success');

        navigate('/login');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Hubo un error al restablecer su contraseña. Por favor, intente nuevamente.');
      }
    } catch (err) {
      setError('Error de conexión. Por favor, verifique su conexión a internet e intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisabled = () => {
    return isLoading || password.length < 8 || password !== confirmPassword || (isEmailChange && (email === '' || email !== confirmEmail));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.default'
      }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: 400,
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" component="h1" align="center" gutterBottom>
            Restablecer Contraseña
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mb: 2 }}>
            <CustomInput
              label="Nueva Contraseña"
              type="password"
              value={password}
              onChange={(value) => setPassword(value)}
              disabled={isLoading}
              fullWidth
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <CustomInput
              label="Confirmar Nueva Contraseña"
              type="password"
              value={confirmPassword}
              onChange={(value) => setConfirmPassword(value)}
              disabled={isLoading}
              fullWidth
            />
          </Box>

          {isEmailChange &&
            <>
              <Box sx={{ mb: 3 }}>
                <CustomInput
                  label="Ingrese su correo electrónico"
                  type="email"
                  value={email}
                  onChange={(value) => setEmail(value)}
                  disabled={isLoading}
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <CustomInput
                  label="Confirme su correo electrónico"
                  type="email"
                  value={confirmEmail}
                  onChange={(value) => setConfirmEmail(value)}
                  disabled={isLoading}
                  fullWidth
                />
              </Box>

            </>


          }

          <CustomButton
            type="submit"
            fullWidth
            isLoading={isLoading}
            disabled={handleDisabled()}
            sx={{ mb: 2 }}
            text={isLoading ? 'Restableciendo...' : 'Restablecer Contraseña'}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomLink to="/login" >
              Volver al inicio de sesión
            </CustomLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
import { useState, useEffect } from "react";
import useUserService from '../../../services/user';
import { useForm } from 'react-hook-form';

const useUserEdit = ({ handleShowEdit, user }) => {
  const MAX_FILE_SIZE_MB = 1;

  // Form
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      hire_date: "",
      id: "",
      immediate_supervisor: "",
      is_active: "",
      last_name: "",
      position: "",
      role_id: "",
      work_location_id: "",
      profile_picture: "",
    },
  });

  const [isEditMode, setIsEditMode] = useState(!!user);
  const [imagePreview, setImagePreview] = useState(user?.profile_picture || '');
  const [imageFile, setImageFile] = useState(null);
  const [data, setData] = useState(getValues());

  const { uploadImage, updateUser, normalizeImage } = useUserService();

  const positions = [
    "ADMINISTRATIVO-PROYECTOS",
    "OPERATIVO",
    "ADMINISTRATIVO",
  ];

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', user?.id);

    const imageText = await uploadImage(formData);
    setValueData('profile_picture', imageText.imageUrl);
  }

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    reset();
    handleShowEdit();
  };

  const setValueData = (name, value) => {
    setValue(name, value);
    setData((prev) => ({ ...prev, [name]: value }));
  }

  const handleValidImage = (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    // Subir imagen al servidor
    const response = normalizeImage(formData);
    console.log('Response:', response);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validar tamaño del archivo original
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        alert(`El archivo supera el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
        return;
      }

      // Validar que el archivo sea una imagen
      if (!file.type.startsWith('image/')) {
        alert('Por favor selecciona un archivo de imagen válido.');
        return;
      }

      setImageFile(file);
      // Crear una previsualización de la imagen
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result);
      };
      reader.readAsDataURL(file);

      handleUploadImage(file);
    }
  };

  const onSubmit = async (data) => {
    if (!data) return;

    if (typeof data.immediate_supervisor === 'object') {
      data.immediate_supervisor = data.immediate_supervisor.id;
    }
    if (typeof data.role_id === 'object') {
      data.role_id = data.role_id.id;
    }

    console.log('Data:', data);

    const reponse = await updateUser(data);
    console.log('Response:', reponse);
    console.log('Errors:', errors);
  };

  useEffect(() => {
    if (user) {
      setImagePreview(user.profile_picture);
      reset(user);
      setData(user);
      setIsEditMode(true);
    } else {
      reset();
      setIsEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    positions,
    isEditMode,
    imageFile,
    imagePreview,
    control,
    data,
    watch,
    register,
    handleDialogClose,
    handleUploadImage,
    handleImageChange,
    handleSubmit,
    handleValidImage,
    setValueData,
    onSubmit
  }
}

export default useUserEdit

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useUserService from '../../../services/user';

const useUserSelect = ({ selectedValue = null, onChange }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const { getUsers } = useUserService();

  const handleSelectedUser = (event, value) => {
    setSelectedUser(value);
    onChange?.(value);
  }

  useEffect(() => {
    const hadleGetUsers = async () => {
      const response = await getUsers({ page: -1, positionsPerPage: -1 });
      setUsers(response.users);
      const user = response.users.find((user) => user.id === selectedValue);

      if (user) {
        setSelectedUser(user);
      } else {
        setSelectedUser(selectedValue);
      }
    }

    hadleGetUsers()
  }, [])

  return {
    users,
    selectedUser,
    handleSelectedUser
  }
}

export default useUserSelect;
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  Chip,
  TableContainer,
  CardContent,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSchedulePerformanceService from '../services/schedulePerformance';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const Reports = () => {
  // state
  const [performanceData, setPerformanceData] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // const
  const containerRef = useRef(null);
  const theme = useTheme();
  const { palette } = theme;

  // services
  const { getSchedulePerformance, downloadCSV } = useSchedulePerformanceService();

  // functions
  const fetchreports = async () => {
    const factors = await getSchedulePerformance();
    setPerformanceData(factors);
  };

  const handleDownload = async () => await downloadCSV();

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;

    const x = e.clientX;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsMouseDown(false);

  const handleMouseLeave = () => setIsMouseDown(false);

  useEffect(() => {
    fetchreports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (performanceData.length === 0) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary'
      }}>
        <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
          <Typography variant="h4" marginBottom={3} color="primary">
            Reporte Subestaciones
          </Typography>
          <Typography variant="h6" marginBottom={3} color="primary">
            No hay reportes disponibles
          </Typography>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" marginBottom={3} color="primary">
            Reporte Subestaciones
          </Typography>
          <FileDownloadIcon onClick={() => handleDownload()} sx={{ cursor: 'pointer' }} />
        </Box>

        <Card>
          <CardContent>
            <TableContainer
              ref={containerRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
              sx={{ cursor: isMouseDown ? 'grabbing' : 'grab', userSelect: 'none' }}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: palette.primary.main }}>
                    <TableCell style={{ color: 'white' }}>Ubicación</TableCell>
                    <TableCell style={{ color: 'white' }}>Fecha</TableCell>
                    <TableCell style={{ color: 'white' }}>Personas</TableCell>
                    <TableCell style={{ color: 'white' }}>Actividades</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performanceData.map((performance, index) => (
                    <TableRow key={performance.performance_id} style={{ backgroundColor: index % 2 === 0 ? palette.background.default : palette.background.paper }}>
                      <TableCell>{performance.location_name}</TableCell>
                      <TableCell>{new Date(performance.created_at).toLocaleString()}</TableCell>
                      <TableCell>
                        {Array.from(new Set(performance.user_names)).map((name, index) => (
                          <Chip key={name} label={name} variant="outlined" style={{ margin: '2px' }} />
                        ))}
                      </TableCell>
                      <TableCell>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ backgroundColor: index % 2 === 0 ? palette.background.paper : palette.background.default }}
                          >
                            <Typography>Ver actividades ({performance.activities.length})</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <Table size="small">
                                <TableHead>
                                  <TableRow style={{ backgroundColor: palette.secondary.main }}>
                                    <TableCell style={{ color: 'white' }}>Actividad</TableCell>
                                    <TableCell style={{ color: 'white' }}>Subactividad</TableCell>
                                    <TableCell style={{ color: 'white' }}>Cantidad</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {performance.activities.map((activity, activityIndex) => (
                                    <TableRow key={activity.activity_name} style={{ backgroundColor: activityIndex % 2 === 0 ? palette.background.default : palette.background.paper }}>
                                      <TableCell>{activity.activity_name}</TableCell>
                                      <TableCell>{activity.subactivity_name}</TableCell>
                                      <TableCell>{activity.activity_quantity}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container >
    </Box >
  );
}

export default Reports;
import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { InsertDriveFile as FileIcon } from '@mui/icons-material';

const files = [
  { name: 'Proyecto 1', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EdP1O788VEBBquF5u9oa9pgBQUbdWlRTIfe_Xn0CHNVJDA?e=qjv3v4' },
  { name: 'Proyecto 2', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EU0TKGTclyhCt6SRR3VcyvwBUeb5JPqaESdoHzDCeCkPkw?e=FOcnMk' },
  { name: 'Proyecto 3', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EbTAKLmxsRlIjYa0HuWvJ6cBIR3LsXvzhIbyh-OimSWXSw?e=iLi6iu' },
  { name: 'Proyecto 4', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EZtz7NHoOT9LqWFGGl6mligB4yByS4bzHm_QtswVUphMwQ?e=2AVrrU' },
  { name: 'Proyecto 5', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EaL-1EAnJWxKumdNMUo0HCcB4LTOL90kwND7wgK0GfUrdQ?e=9NrZ4R' },
];

const Ejecucion = () => {
  return (
    <>
      <div>
        <h1>Proyectos en ejecución</h1>
      </div>
      <Paper elevation={3} sx={{ margin: 'auto', mt: 2, p: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Listado
        </Typography>
        <List>
          {files.map((file, index) => (
            <ListItem key={file} disablePadding>
              <Link
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="inherit"
                sx={{ display: 'flex', width: '100%', py: 1 }}
              >
                <ListItemIcon>
                  <FileIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={file.name} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  )
}

export default Ejecucion;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useProjectService from '../../../services/projects'
import { useForm } from 'react-hook-form';


const useNominaProject = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedOT, setSelectedOT] = useState('');
  const [selectedException, setSelectedException] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startShiftHour, setStartShiftHour] = useState(null);
  const [endShiftHour, setEndShiftHour] = useState(null);
  const [realShiftHour, setRealShiftHour] = useState(null);
  const [selectedRestHour, setSelectedRestHour] = useState(null);

  const data = [
    {
      id: 1,
      name: 'Juan Perez',
      exception: 'Sin novedad',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:15',
      restHour: '1'
    },
    {
      id: 2,
      name: 'Maria Gomez',
      exception: 'Incapacidad general',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 3,
      name: 'Carlos Rodriguez',
      exception: 'Ingreso',
      startHour: '09:00',
      endHour: '18:00',
      realEndHour: '18:00',
      restHour: '1'
    },
    {
      id: 4,
      name: 'Ana Martinez',
      exception: 'Permisos parcial',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '16:00',
      restHour: '1'
    },
    {
      id: 5,
      name: 'Luis Hernandez',
      exception: 'Vacaciones',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 6,
      name: 'Laura Lopez',
      exception: 'Licencia paternidad',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 7,
      name: 'Jorge Sanchez',
      exception: 'Licencia por luto',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 8,
      name: 'Sofia Ramirez',
      exception: 'Retiro',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 9,
      name: 'Miguel Torres',
      exception: 'Ausencia injustificada',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    },
    {
      id: 10,
      name: 'Isabel Fernandez',
      exception: 'Incapacidad laboral',
      startHour: '08:00',
      endHour: '17:00',
      realEndHour: '17:00',
      restHour: '1'
    }
  ];


  const { getProjects } = useProjectService();

  const handleFieldChange = ({ value, arrayValue = null, setValue, localStorageKey }) => {
    let auxValue = [];

    if (!arrayValue) {
      setValue(value);
      localStorage.setItem(localStorageKey, value);

    } else if (value.length > 0) {
      if (new Set(value.map((x) => x.id)).size !== value.length) {
        return;
      }
      value.map((x) => auxValue.push(x.id));
      setValue(auxValue);
    } else {
      auxValue = value;
      setValue(auxValue);
    }
  }

  const handleGetProjects = async (currentPage, positionsPerPage) => {
    const response = await getProjects();
    setProjects(response);
  }

    // Form
    const {
      control,
      register,
    } = useForm({
      defaultValues: {
        checkAll: false,
        email: "",
        first_name: "",
        hire_date: "",
        id: "",
        immediate_supervisor: "",
        is_active: "",
        last_name: "",
        position: "",
        role_id: "",
        work_location_id: "",
        profile_picture: "",
      },
    });

  useEffect(() => {
    handleGetProjects();
  }, []);

  return {
    data,
    control,
    register,
    projects,
    selectedOT,
    selectedDate,
    selectedUser,
    endShiftHour,
    realShiftHour,
    startShiftHour,
    selectedProject,
    selectedAccount,
    selectedRestHour,
    selectedException,
    setSelectedOT,
    setSelectedDate,
    setSelectedUser,
    setEndShiftHour,
    setRealShiftHour,
    setStartShiftHour,
    setSelectedProject,
    setSelectedRestHour,
    setSelectedAccount,
    setSelectedException,
    handleFieldChange,
  };

}

export default useNominaProject;
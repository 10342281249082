import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import "dayjs/locale/en";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomCalendar = ({ setSelectedDate }) => {

  const { useCalendar } = useComponentsControllers();
  const { innerSelectedDate, isLoading, getHighlightedDays, ServerDay } = useCalendar({ setSelectedDate });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DateCalendar
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{ highlighted: getHighlightedDays() }}
        defaultValue={''}
        value={innerSelectedDate}
      />
    </LocalizationProvider>
  );
};

export default CustomCalendar;
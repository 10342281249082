import React from 'react';
import { Avatar, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, MenuItem, InputLabel, Select, Typography } from '@mui/material';
import { CustomButton } from '../../components/CustomButton';
import useComponent from '../../components';
import useUserEdit from '../../controllers/viewsControllers/user/userEdit';
import CloseIcon from '@mui/icons-material/Close';

const EditUser = ({ isEditDialogOpen, handleShowEdit, user }) => {
  const {
    positions,
    isEditMode,
    imageFile,
    imagePreview,
    control,
    data,
    watch,
    register,
    handleDialogClose,
    setValueData,
    handleImageChange,
    handleSubmit,
    onSubmit,
  } = useUserEdit({ handleShowEdit, user });

  const { CustomDatePicker, CustomUserSelect, CustomRoleSelect, CustomInput, CustomCheckBox } = useComponent();

  if (!user && isEditMode) return;

  return (
    <Dialog
      open={isEditDialogOpen}
      onClose={handleDialogClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        '& .MuiDialog-container': {
          width: '100%',
        },
      }}
      PaperProps={{
        sx: {
          width: '100%',
        }
      }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant='h4'>
            {isEditMode ? `Editar Usuario` : `Crear usuario`}
          </Typography>
          {user && <Typography variant="h6">{`${user?.first_name} ${user?.last_name}`}</Typography>}
        </Box>

        <Button onClick={handleDialogClose} color='white' p={0} m={0}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      {/* <FacialRecognition onValidImage={handleValidImage} /> */}
      <form onSubmit={handleSubmit(onSubmit)}>

        <DialogContent>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={imagePreview}
                alt={`${user?.first_name} ${user?.last_name}`}
                sx={{ width: 100, height: 100, border: '1px solid white', borderRadius: '50%' }}
              />
              <Button variant="contained" component="label">
                {isEditMode ? 'Cambiar foto' : 'Subir foto'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomDatePicker
              label="Fecha de contratación"
              value={watch("hire_date")}
              ref={register}
              onChange={(value) => setValueData('hire_date', value)}
              name="hire_date"
              control={control}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomRoleSelect
              label="Roles"
              value={watch("role_id") || user?.role_id}
              onChange={(value) => setValueData('role_id', value)}
              name="role_id"
              ref={register}
              control={control}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Seleccione una posición</InputLabel>
              <Select
                label="Seleccione una posición"
                name="position"
                value={watch("position") || user?.position}
                onChange={(e) => setValueData('position', e.target.value)}
                sx={{ borderRadius: '8px' }}
              >
                {positions.map((position) => (
                  <MenuItem key={position} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomInput
              label="Número de documento"
              type="number"
              value={watch("id")}
              ref={register}
              onChange={(value) => setValueData('id', value)}
              fullWidth
              name="id"
              control={control}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomInput
              label="Nombres"
              type="text"
              name="first_name"
              value={watch("first_name")}
              {...register('first_name')}
              onChange={(value) => setValueData('first_name', value)}
              fullWidth
              control={control}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomInput
              label="Apellidos"
              type="text"
              name="last_name"
              value={watch("last_name")}
              {...register('last_name')}
              onChange={(value) => setValueData('last_name', value)}
              fullWidth
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomInput
              label="Correo electrónico"
              type="email"
              name="email"
              value={watch("email")}
              {...register('email')}
              onChange={(value) => setValueData('email', value)}
              fullWidth
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomUserSelect
              label="Supervisor"
              value={watch("immediate_supervisor") || user?.immediate_supervisor}
              name="immediate_supervisor"
              ref={register}
              onChange={(value) => setValueData('immediate_supervisor', value)}
              control={control}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <CustomCheckBox
              label="Activo"
              checked={user?.is_active}
              value={data?.is_active}
              isDisable={!user?.email}
              ref={register}
              control={control}
              name="is_active"
            />
          </Box>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>Cancelar</Button>
          <CustomButton
            type="submit"
            isLoading={false}
            onClick={() => {
              onSubmit();
              if (imageFile) {
                console.log('Archivo seleccionado:', imageFile);
                // Aquí puedes manejar la subida del archivo a S3 o tu backend
              }
              // handleShowEdit();
            }}
            text='Guardar'
          />

        </DialogActions>
      </form>

    </Dialog>
  );
}

export default EditUser;
import CustomPagination from './CustomPagination';
import CustomDatePicker from './CustomDatePicker';
import CustomRoleSelect from './CustomRoleSelect';
import CustomUserSelect from './CustomUserSelect';
import CustomInput from './CustomInput';
import CustomCheckBox from './CustomCheckBox';
import CustomCalendar from './CustomCalendar';

const useComponent = () => {
  return {
    CustomPagination,
    CustomDatePicker,
    CustomRoleSelect,
    CustomUserSelect,
    CustomInput,
    CustomCheckBox,
    CustomCalendar,
  };
}

export default useComponent;
import axios from 'axios';
import useAuth from './auth';

const useProjectService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();

  const token = getToken();
  if (!token) logout();

  const getProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching projects:', error);
      return [];
    }
  };


  return {
    getProjects
  };
}

export default useProjectService;
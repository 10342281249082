// src/pages/ForgotPassword.js
import React, { useState } from 'react';
import { Box, Typography, Alert } from '@mui/material';
import { CustomButton } from '../components/CustomButton';
import { CustomLink } from '../components/CustomLink';
import axios from 'axios';
import useComponent from '../components';

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const ForgotPasswordPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { CustomInput } = useComponent();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!validateEmail(email)) {
      setError('Por favor, ingrese un correo electrónico válido.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/password/forgot-password`, JSON.stringify({ email }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccess('Se ha enviado un enlace de recuperación a su correo electrónico.');
        setEmail('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Hubo un error al procesar su solicitud. Por favor, intente nuevamente.');
      }
    } catch (err) {
      setError('Error de conexión. Por favor, verifique su conexión a internet e intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.default'
      }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: 400,
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" component="h1" align="center" gutterBottom>
            Recuperar Contraseña
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}

          <Box sx={{ mb: 3 }}>
            <CustomInput
              label="Correo Electrónico"
              type="email"
              name="email"
              value={email}
              onChange={(value) => setEmail(value)}
              disabled={isLoading}
              fullWidth
            />
          </Box>

          <CustomButton
            type="submit"
            fullWidth
            isLoading={isLoading}
            sx={{ mb: 2 }}
            text={isLoading ? 'Enviando...' : 'Enviar enlace de recuperación'}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CustomLink to="/login" >
              Volver al inicio de sesión
            </CustomLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;

import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { InsertDriveFile as FileIcon } from '@mui/icons-material';

const files = [
  { name: 'Proyecto 1', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EUPp9UB332pHlCZX7c0u4ssBY-YIXI-amZHY7gGW_XBM1w?e=eAcaok' },
  { name: 'Proyecto 2', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/ERnqsrwwfqNFqEQcr_IjSD0BvHSsXTmIAi0tfJ0fNgo5Og?e=uzwWa1' },
  { name: 'Proyecto 3', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/ERnqsrwwfqNFqEQcr_IjSD0BvHSsXTmIAi0tfJ0fNgo5Og?e=uzwWa1' },
  { name: 'Proyecto 4', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/Eaw_OXVx8WtHoubcYVJvMFMBMLk_Nv2HF_8jBnSDLd_Gmg?e=m6N2QO' },
  { name: 'Proyecto 5', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/Ebo-tPFxWCRIpd5I3FH2tlABdjtwsXTwTxwX-vAvO7MlJQ?e=GW7Njv' },
  { name: 'Proyecto 6', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EVnfVERp_yNJnddfB6FSi0EBASmiKPgBGUhX1ZI1zS9eJg?e=a3OG3R' },
  { name: 'Proyecto 7', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EV6ajKKxrkRFkhA8LSm885MBXCJPM-9GtJOufV5q975RVA?e=LxDlck' },
  { name: 'Proyecto 8', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EYHcCcGw165On20keHedp9wBpv58frwXmKnMx63pwvdeLg?e=ckfdsT' },
  { name: 'Proyecto 9', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EadNyz6LP9NMtTmrT0ZnzCgBf4IXU7apjm8KkmCZwrF3kA?e=qC5x9V' },
  { name: 'Proyecto 10', url: 'https://eliteingenierosas.sharepoint.com/:w:/s/ProyectosUnidaddeTI/EWFKIjO-LCxPorXFeyLzk1cBV1G7rV2i-LKEu3BnRkeoDg?e=M9dfxU' },
];


const Propuestas = () => {
  return (
    <>
      <div>
        <h1>Proyectos propuestos</h1>
      </div>
      <Paper elevation={3} sx={{ margin: 'auto', mt: 2, p: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Listado
        </Typography>
        <List>
          {files.map((file, index) => (
            <ListItem key={file} disablePadding>
              <Link
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="inherit"
                sx={{ display: 'flex', width: '100%', py: 1 }}
              >
                <ListItemIcon>
                  <FileIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={file.name} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  )
}

export default Propuestas;
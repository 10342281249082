import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../views/Login';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import Dashboard from '../views/Dashboard';
import FollowUp from '../views/FollowUp';
import SchedulePerformance from '../views/SchedulePerformance';
import Home from '../views/Home';
import Reports from '../views/Reports';
import GeoFence from '../views/GeoFence';
import Role from '../views/Role';
import User from '../views/User';
import PublicLighting from '../views/PublicLighting';
import PublicLightReports from '../views/PublicLightReports';
import Propuestas from '../views/Propuestas';
import Ejecucion from '../views/Ejecucion';
import Nomina from '../views/Nomina';
import NominaProjects from '../views/NominaProjects';
import Permissions from '../views/Permissions';

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/password-reset/:token" element={<ResetPassword />} />
      <Route exact path="/email-password-reset/:token" element={<ResetPassword isEmailChange={true} />} />
      <Route exact path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/evaluacion" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/evaluaciones/seguimiento" element={<PrivateRoute><FollowUp /></PrivateRoute>} />
      <Route path="/evaluaciones/seguimiento/:userId" element={<PrivateRoute><FollowUp /></PrivateRoute>} />
      <Route path="/kpi" element={<PrivateRoute><div>Gráficos</div></PrivateRoute>} />
      <Route path="/kpi/subestaciones" element={<PrivateRoute><SchedulePerformance /></PrivateRoute>} />
      <Route path="/kpi/seguimiento-subestaciones" element={<PrivateRoute><Reports /></PrivateRoute>} />
      <Route path="/kpi/alumbrado-publico" element={<PrivateRoute><PublicLighting /></PrivateRoute>} />
      <Route path="/kpi/seguimiento-alumbrado-publico" element={<PrivateRoute><PublicLightReports /></PrivateRoute>} />
      <Route path="/admin/geocercas" element={<PrivateRoute><GeoFence /></PrivateRoute>} />
      <Route path="/admin/roles" element={<PrivateRoute><Role /></PrivateRoute>} />
      <Route path="/admin/permisos" element={<PrivateRoute><Permissions /></PrivateRoute>} />
      <Route path="/admin/usuarios" element={<PrivateRoute><User /></PrivateRoute>} />
      <Route path="/proyectos/propuestas" element={<PrivateRoute><Propuestas /></PrivateRoute>} />
      <Route path="/proyectos/ejecucion" element={<PrivateRoute><Ejecucion /></PrivateRoute>} />
      <Route path="/nomina" element={<PrivateRoute><Nomina /></PrivateRoute>} />
      <Route path="/nomina/proyectos" element={<PrivateRoute><NominaProjects /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesApp;
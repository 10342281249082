import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomRoleSelect = ({ value, onChange }) => {
  const { useRoleSelect } = useComponentsControllers();
  const { roles, selectedRole, handleSelectedRole } = useRoleSelect({ selectedValue: value, onChange });

  return (
    <Autocomplete
      options={roles}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option?.name}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={selectedRole}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Roles" />}
      onChange={(event, newValue) => handleSelectedRole(event, newValue)}
    />
  );
};

export default CustomRoleSelect;
